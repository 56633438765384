:root {
    --tg-theme-bg-color: #ffffff;
    --tg-theme-text-color: #000000;
    --tg-theme-hint-color: #999999;
    --tg-theme-link-color: #0000ee;
    --tg-theme-button-color: #0088cc;
    --tg-theme-button-text-color: #ffffff;
    --tg-theme-secondary-bg-color: #f5f5f5;
    --tg-theme-header-bg-color: #cccccc;
    --tg-theme-accent-text-color: #ff0000;
    --tg-theme-section-bg-color: #eeeeee;
    --tg-theme-section-header-text-color: #333333;
    --tg-theme-section-separator-color: #dddddd;
    --tg-theme-subtitle-text-color: #666666;
    --tg-theme-destructive-text-color: #ff4444;

    --bar-chart-color: #007bff;
}




body{
    background-color: var(--tg-theme-secondary-bg-color) !important;
    color: var(--tg-theme-text-color) !important;
}
.bg-body {
    background-color: var(--tg-theme-secondary-bg-color) !important;
    color: var(--tg-theme-text-color) !important;
}

.bg-secondary{
    background-color: var(--tg-theme-bg-color) !important;
}
.bg-secondary input,textarea{
    background-color: var(--tg-theme-bg-color) !important;
    color: var(--tg-theme-text-color)!important;
    border-color: var(--tg-theme-hint-color) !important;
}

.card{
    background-color: var(--tg-theme-bg-color) !important;
    color: var(--tg-theme-text-color) !important;
}

.form-check-input{
    background-color: var(--tg-theme-hint-color) !important;
    color: var(--tg-theme-text-color) !important;
    border-color: var(--tg-theme-hint-color) !important;
}

.form-check-input:checked{
    background-color: var(--tg-theme-button-color) !important;
}
.input-group-text{
    background-color: var(--tg-theme-button-color);
    color: var(--tg-theme-text-color) !important;
}
.form-control, .form-select{
    background-color: var(--tg-theme-secondary-bg-color) !important;
    color: var(--tg-theme-text-color) !important;
    border-color: var(--tg-theme-hint-color) !important;
}

.bg-secondary input,textarea:focus{
    color: var(--tg-theme-text-color)!important;
    border-color: var(--tg-theme-button-color) !important;
}

.bg-secondary input,textarea::placeholder {
    color: var(--tg-theme-hint-color)!important ;
    opacity: 1 !important;
}


.hint-text{
    color: var(--tg-theme-hint-color) !important;
}

.hint-fill{
    fill: var(--tg-theme-hint-color) !important;
}

.btn-primary{
    background-color: var(--tg-theme-button-color);
    border-color: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color)
}
.btn-primary:hover{
    background-color: var(--tg-theme-button-color);
    border-color: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color)
}
.btn-primary:active{
    background-color: var(--tg-theme-button-color);
    border-color: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color)
}


a{
    color: var(--tg-theme-text-color) !important;
    text-decoration: none;
}

.tg-text-color{
    color: var(--tg-theme-text-color) !important;
}

svg{
    fill: var(--tg-theme-accent-text-color) !important;
}

.stroke-svg-color{
    stroke: var(--tg-theme-accent-text-color) !important;
}

.separated-list li{
    border-bottom: 2px solid var(--tg-theme-secondary-bg-color);
}
.separated-list li a{
    text-decoration: none;
}
.separated-list li:last-child {
    border-bottom: none;
}

blockquote{
    border-left: 0.2em solid var(--tg-theme-accent-text-color);
    padding-left: 0.2em;
}

.disabled{
    background-image: repeating-linear-gradient(45deg, var(--tg-theme-hint-color) 0, var(--tg-theme-hint-color) 1rem, transparent 1rem, transparent 3rem);
}

.btn-link{
    color: var(--tg-theme-link-color)
}


.card-header{
    background-color: var(--tg-theme-header-bg-color);
}

.w-md-editor-text-input {
    -webkit-text-fill-color: var(--tg-theme-text-color) !important;
}


.w-md-editor textarea{
    background-color: unset!important;
}

.w-md-editor-fullscreen{
    height: 100vh!important;
}

.w-md-editor .blockquote{
    font-size:unset!important;
}

.table-dark{
    --bs-table-bg: var(--tg-theme-bg-color)!important;
}